@import "./variables.scss";

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

:root {
  --safe-area: max(env(safe-area-inset-bottom), 20px);
}

// 인증관련 화면
.auth-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.auth-form {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.auth-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  padding-right: 10px;
}

// app
.app-container {
  display: flex;
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 100vw;
  font-family: Arial, sans-serif;
  position: relative;
  overflow-x: hidden;
}

.close-sidebar,
.open-sidebar {
  background-color: $primary-color;
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.close-sidebar {
  font-size: 18px;
  padding: 5px 8px;
}

.open-sidebar {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999;
  font-size: 1.2rem;
  padding: 8px 10px;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease-in-out;
  padding-right: 5;
  padding-left: 5%;
  &.sidebar-open {
    margin-left: $sidebar-width;
  }

  .chat-header {
    font-size: 1.25rem;
    font-weight: 700;
    padding: 0;
    text-align: end;
    padding-right: 5%;
  }
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-top: 0;
  padding-left: 0px;
  background-color: white;
  font-weight: 500;

  .message-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &.assistant {
      align-items: flex-start;
    }

    &.user {
      align-items: flex-end;
    }
  }

  .message {
    display: flex;
    align-items: flex-start;
    min-width: 100%;

    &.assistant {
      flex-direction: row;
    }

    &.user {
      flex-direction: row-reverse;
    }

    .message-content {
      padding: 0rem 0.5rem;
      border-radius: 18px;
      max-width: 70%;
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
      font-size: $mobile-fs;

      p {
        padding: 0;
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }
    }

    pre,
    code {
      white-space: pre-wrap; // 코드 블록 줄바꿈
      word-break: break-all; // 모든 문자에서 줄바꿈 허용
    }

    &.assistant {
      .message-content {
        // background-color: $assistant-bg;
        border: 1px solid $assistant-border;
        color: $text-color;
        border-bottom-left-radius: 0;
        margin-left: 10px;
        max-width: 70vw;
      }

      a {
        color: $primary-color;
        word-break: break-all;
      }
    }

    &.user {
      .message-content {
        // background-color: $user-bg;
        border: 1px solid $user-border;
        color: $text-color;
        border-bottom-right-radius: 0;
        margin-right: 10px;
        max-width: 70vw;
      }
    }
  }

  // .assistant-icon {
  //   width: 30px;
  //   height: 30px;
  //   border-radius: 50%;
  //   object-fit: cover;
  //   margin-top: 1rem;
  // }
}

.assistant-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  // margin-top: 1rem;
}

.message-content.generating {
  // background-color: transparent !important;
  // border: 1px solid $assistant-bg;
  background-color: $assistant-bg;
  border: transparent !important;
  color: darken($text-color, 20%);
  font-style: italic;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem !important;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    margin-left: 8px;
    background-color: $primary-color;
    border-radius: 50%;
    display: inline-block;
    animation: pulse 1s infinite ease-in-out;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}

.message-content.error {
  // background-color: transparent !important;
  // border: 1px solid $assistant-bg;
  border: transparent !important;
  background-color: $assistant-bg;
  color: darken($text-color, 20%);
  font-style: italic;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem !important;
  font-weight: 600;
}

.input-form {
  display: flex;
  padding: 20px;

  textarea {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid darken($secondary-color, 10%);
    border-radius: 10px;
    // font-size: 0.8rem;
    font-size: 16px;
    outline: none;

    &:focus {
      border-color: $primary-color;
    }
  }

  button {
    background-color: $primary-color;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    max-height: 4rem;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      background-color: lighten($primary-color, 20%);
      cursor: not-allowed;
    }
  }

  .stop {
    background-color: $text-color;
  }
}

//chat guide
.chat-guide {
  background-color: $guide-bg;
  border: 1px solid $guide-border;
  border-radius: 8px;
  padding: 2.5px 2.5px;
  font-size: $mobile-fs;
  margin-right: 3%;
  margin-bottom: 0.7rem;

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 5px;
      color: $sub-text-color;
    }
  }

  strong {
    color: $primary-color;
  }
}

@media only screen and (min-width: 1000px) {
  .messages-container {
    margin-right: 3%;
    .chat-guide {
      margin: 0;
      margin-bottom: 1rem;
    }
    .message {
      .message-content {
        font-size: $desktop-fs;
        padding: 0rem 0.75rem;
      }
    }
  }

  .chat-guide {
    font-size: $desktop-fs;
  }
}
