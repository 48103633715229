@import "../../variables.scss";

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  font-family: Arial, sans-serif;
  position: relative;
  overflow-x: hidden;
}

.sidebar {
  width: $sidebar-width;
  background-color: $secondary-color;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      color: $primary-color;
    }
  }

  form {
    margin-top: 20px;

    textarea {
      width: 100%;
      height: 100px;
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid darken($secondary-color, 10%);
      border-radius: 5px;
      resize: vertical;
    }

    button {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

.close-sidebar,
.open-sidebar {
  background-color: $primary-color;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.close-sidebar {
  font-size: 18px;
  padding: 5px 8px;
}

.open-sidebar {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 999;
  font-size: 1.2rem;
  padding: 8px 10px;
}
