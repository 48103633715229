@import "../../variables.scss";

.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.2rem;
}

.feedback-buttons {
  display: flex;
  margin-bottom: 10px;
}

.feedback-button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 5px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  opacity: 0.4;

  &:hover:not(:disabled) {
    opacity: 1;
  }

  &.active {
    color: $primary-color;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.additional-feedback {
  display: flex;
  width: 70vw;
  margin-top: 10px;

  input {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 0.8rem;
  }

  input:focus {
    border: 1px solid $primary-color;
    outline: none;
  }

  button {
    background-color: transparent;
    color: $primary-color;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
}
